<template>
  <div>
    <b-modal
      ref="devisModal"
      id="devisModal"
      size="xl"
      scrollable
      no-close-on-backdrop
    >
      <template #modal-header>
        <h5>{{ $t("Modification") }} {{ devis.devis.numerodevis }}</h5>
        <b-button size="sm" @click="resetModal()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17.028"
            height="17.028"
            viewBox="0 0 17.028 17.028"
          >
            <path
              id="Icon_material-close"
              data-name="Icon material-close"
              d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
              transform="translate(-7.5 -7.5)"
              fill="#393939"
            />
          </svg>
        </b-button>
      </template>
      <form @submit.prevent="editDevis" v-if="devisObject">
        <div class="center">
          <b-form-group :label="$t('DATE_VISITE_TECHNIQUE')">
            <b-form-input
              type="date"
              v-model="devisObject.devis.visite_technique"
              aria-describedby="visite_technique-feedback"
            ></b-form-input>
          </b-form-group>
          <b-form-group :label="$t('Date de devis')">
            <b-form-input
              type="date"
              v-model="devisObject.devis.debut_devis"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group :label="$t('DATE-FIN')">
            <b-form-input
              type="date"
              v-model="devisObject.devis.fin_devis"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group :label="$t('TYPE_DEVIS')">
            <b-form-select
              v-model="devisObject.devis.prime"
              :options="optionsprime"
              text-field="text"
              value-field="value"
            ></b-form-select>
            <div v-if="erreurlist.prime" class="error-message">
              <ul v-if="Array.isArray(erreurlist.prime)">
                <span v-for="(e, index) in erreurlist.prime" :key="index">
                  {{ e }}
                </span>
              </ul>
              <span v-else>{{ erreurlist.prime }}</span>
            </div>
          </b-form-group>

          <b-form-group
            :label="$t('INTERLOCUTEUR')"
            v-if="devisObject.devis.prime != 'devis_libre'"
          >
            <multiselect
              v-model="devisObject.devis.interlocuteur"
              :searchable="true"
              :close-on-select="true"
              :multiple="false"
              :options="getAllUsers"
              label="nom"
            >
              <template slot="noResult"> {{ $t("NO_DATA_FOUND") }} </template>
            </multiselect>
            <div v-if="erreurlist.interlocuteur_id" class="error-message">
              <ul v-if="Array.isArray(erreurlist.interlocuteur_id)">
                <span
                  v-for="(e, index) in erreurlist.interlocuteur_id"
                  :key="index"
                >
                  {{ e }}
                </span>
              </ul>
              <span v-else>{{ erreurlist.interlocuteur_id }}</span>
            </div>
          </b-form-group>

          <b-form-group :label="$t('DELEGATAIRE')">
            <multiselect
              v-model="devisObject.devis.delegataire"
              :searchable="true"
              :close-on-select="true"
              :multiple="false"
              :options="getAllDelegataire"
              label="nom"
            >
              <template slot="noResult"> {{ $t("NO_DATA_FOUND") }} </template>
            </multiselect>
            <div v-if="erreurlist.delegataire_id" class="error-message">
              <ul v-if="Array.isArray(erreurlist.delegataire_id)">
                <span
                  v-for="(e, index) in erreurlist.delegataire_id"
                  :key="index"
                >
                  {{ e }}
                </span>
              </ul>
              <span v-else>{{ erreurlist.delegataire_id }}</span>
            </div>
          </b-form-group>

          <b-form-group :label="$t('ESTIMATE_DESC')">
            <b-form-textarea
              v-model="v$.devisObject.devis.description.$model"
              size="lg"
              :placeholder="$t('ESTIMATE_DESC')"
              rows="3"
              max-rows="6"
              :state="validateState('description')"
              aria-describedby="description-feedback"
            ></b-form-textarea>
            <error-handle
              :list="v$.devisObject.devis.description.$errors"
              id="description-feedback"
            ></error-handle>
            <div v-if="erreurlist.description" class="error-message">
              <ul v-if="Array.isArray(erreurlist.description)">
                <span v-for="(e, index) in erreurlist.description" :key="index">
                  {{ e }}
                </span>
              </ul>
              <span v-else>{{ erreurlist.description }}</span>
            </div>
          </b-form-group>

          <v-checkbox
            style="margin: 0; padding: 0"
            v-model="devisObject.devis.same_travaux_address"
            :label="`${$t('QUESTION_ADRESSE')}`"
          ></v-checkbox>

          <b-form-group
            :label="$t('TRAVAUX_RUE')"
            v-if="!devisObject.devis.same_travaux_address"
          >
            <b-form-input
              :placeholder="$t('TRAVAUX_RUE')"
              v-model="devisObject.devis.rue_travaux"
              :state="validateState('rue_travaux')"
              aria-describedby="rue_travaux-feedback"
            ></b-form-input>
            <error-handle
              :list="v$.devisObject.devis.rue_travaux.$errors"
              id="rue_travaux-feedback"
            ></error-handle>
          </b-form-group>

          <b-form-group
            :label="$t('TRAVAUX_CP')"
            v-if="!devisObject.devis.same_travaux_address"
          >
            <b-form-input
              v-model="devisObject.devis.cp_travaux"
              :placeholder="$t('TRAVAUX_CP')"
              :state="validateState('cp_travaux')"
              aria-describedby="cp_travaux-feedback"
            ></b-form-input>
            <error-handle
              :list="v$.devisObject.devis.cp_travaux.$errors"
              id="cp_travaux-feedback"
            ></error-handle>
          </b-form-group>

          <b-form-group
            :label="$t('TRAVAUX_VILLE')"
            v-if="!devisObject.devis.same_travaux_address"
          >
            <b-form-input
              v-model="devisObject.devis.ville_travaux"
              :placeholder="$t('TRAVAUX_VILLE')"
              :state="validateState('ville_travaux')"
              aria-describedby="ville_travaux-feedback"
            ></b-form-input>
            <error-handle
              :list="v$.devisObject.devis.ville_travaux.$errors"
              id="ville_travaux-feedback"
            ></error-handle>
          </b-form-group>

          <div style="width: 50%" v-if="devisObject.devis.same_travaux_address">
            <p class="address">
              Adresse: {{ devisObject.client.rue }},
              {{ devisObject.client.cp }},
              {{ devisObject.client.ville }}
            </p>
          </div>

          <div
            class="group"
            style="width: 100%"
            v-for="(group, i) in devisObject.groupeLigneDocument"
            :key="i"
          >
            <div class="piece">
              <p>
                {{ $t("PIECE_") }}: {{ group.piece }}
                <span
                  class="edit"
                  @click="openPiece(i)"
                  v-if="group.piece != 'Divers'"
                >
                  <font-awesome-icon icon="pencil-alt" />
                </span>
              </p>
              <div class="icone-down-up">
                <b-button size="sm" @click="closeGroup(i)" class="mr-2">
                  <font-awesome-icon
                    icon="chevron-down"
                    class="icons"
                    v-if="!showGroup[i].bool"
                  />
                  <font-awesome-icon icon="chevron-up" class="icons" v-else />
                </b-button>
                <b-button size="sm" @click="add_to_trash(group, i)">
                  <font-awesome-icon icon="trash" class="icons" />
                </b-button>
              </div>
            </div>
            <div class="part" v-show="showGroup[i].bool">
              <ul>
                <li class="traveauxtitle">
                  <span class="title" style="font-weight: 600,font-size: 14px;"
                    >{{ $t("WORK") }} : {{ group.traveaux }}</span
                  >
                  <span class="icons" @click="openTravaux(i)">
                    <font-awesome-icon icon="pencil-alt" />
                  </span>
                </li>
                <li class="traveauxtitle" v-if="group.traveaux">
                  <span class="title" style="font-weight: 600,font-size: 14px;">
                    {{ $t("SOUS-TRAITANT") }} :
                    <span v-if="group.sous_traitant">
                      {{ group.sous_traitant.name }}
                    </span>
                  </span>
                  <span class="icons" @click="opensousTraitant(i)">
                    <font-awesome-icon icon="pencil-alt" />
                  </span>
                  <span
                    class="iconsred"
                    @click="deleteSousTraitant(i)"
                    v-if="group.sous_traitant"
                  >
                    <b-icon-x></b-icon-x>
                  </span>
                </li>
              </ul>
              <table class="table">
                <thead>
                  <tr>
                    <th
                      style="background-color: #fff; min-width: 32px; border: 0"
                    ></th>
                    <th style="border-radius: 5px 0px 0px 0px" scope="col">
                      {{ $t("REF") }}
                    </th>
                    <th>{{ $t("WORDING") }}/{{ $t("DESC") }}</th>
                    <th>{{ $t("QTE") }}</th>
                    <th>{{ $t("UNITEU") }}</th>
                    <th>{{ $t("PUHT") }}</th>
                    <th v-show="devisObject.remise_mode == 'par_ligne'">
                      {{ $t("REMISE") }}
                    </th>
                    <th style="border-radius: 0px 5px 0px 0px">
                      {{ $t("VAT") }}
                    </th>
                  </tr>
                </thead>
                <tbody
                  v-for="(
                    product, y
                  ) in devisObject.GroupeLigneDocument_produit"
                  :key="y"
                >
                  <tr
                    v-if="group.id == product.groupelignedocument_id"
                    :class="{ errorBG: errorProduits[y] }"
                  >
                    <td>
                      <div @click="popProduit(group, product)" class="del">
                        <font-awesome-icon icon="trash" class="icons" />
                      </div>
                    </td>
                    <td>
                      <b-form-input
                        id="lib"
                        v-model="product.ref"
                        @update="editProduct(product)"
                        required
                      ></b-form-input>
                    </td>
                    <td>
                      <b-form-input
                        style="margin-bottom: 4px"
                        id="lib"
                        v-model="product.lib"
                        @update="editProduct(product)"
                        aria-describedby="lib-feedback"
                      ></b-form-input>

                      <b-form-input
                        v-model="product.desc"
                        @update="editProduct(product)"
                      ></b-form-input>
                      <error-handle
                        :list="v$.produit.$errors"
                        id="lib-feedback"
                      ></error-handle>
                    </td>
                    <td>
                      <b-form-input
                        type="number"
                        min="1"
                        v-model="product.quantite"
                        @update="editProduct(product)"
                        required
                      ></b-form-input>
                    </td>
                    <td>
                      <b-form-select
                        v-model="product.type_unite"
                        :options="optionstype"
                        text-field="text"
                        value-field="value"
                        @change="editProduct(product)"
                      ></b-form-select>
                    </td>
                    <td>
                      <b-form-input
                        v-model="product.unit_price"
                        type="number"
                        step="1"
                        min="0"
                        max="10000"
                        @update="editProduct(product)"
                      ></b-form-input>
                    </td>
                    <td v-show="devisObject.remise_mode == 'par_ligne'">
                      <b-form-input
                        v-model="product.remise_value"
                        type="number"
                        step="1"
                        min="0"
                        max="10000"
                        @update="editProduct(product, true)"
                      ></b-form-input>
                      <b-form-select
                        v-model="product.remise_type"
                        :options="optionsRemiseType"
                        @change="editProduct(product, true)"
                      ></b-form-select>
                    </td>
                    <td>
                      <b-form-select
                        v-model="product.tva"
                        :options="optionsTVA"
                        @change="editProduct(product)"
                      ></b-form-select>
                    </td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td>
                      <div
                        class="add"
                        :title="$t('ADD') + ' ' + $t('PRODUCT')"
                        @click="addJokerProduit(group, i)"
                        v-if="group.piece == 'Divers'"
                      >
                        <font-awesome-icon icon="plus" class="icons" />
                      </div>
                      <div
                        class="add"
                        :title="$t('ADD') + ' ' + $t('PRODUCT')"
                        @click="openproduit(group, i)"
                        v-else
                      >
                        <font-awesome-icon icon="plus" class="icons" />
                      </div>
                    </td>
                    <td
                      v-for="i in devisObject.remise_mode == 'par_ligne'
                        ? 7
                        : 6"
                      :key="i"
                    >
                      <div class="detail_pvw"></div>
                    </td>
                  </tr>
                </tbody>
                <div v-if="erreurlist.ref" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.ref)">
                    <span v-for="(e, index) in erreurlist.ref" :key="index">
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.ref }}</span>
                </div>
                <div v-if="erreurlist.lib" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.lib)">
                    <span v-for="(e, index) in erreurlist.lib" :key="index">
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.lib }}</span>
                </div>
                <div v-if="erreurlist.desc" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.desc)">
                    <span v-for="(e, index) in erreurlist.desc" :key="index">
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.desc }}</span>
                </div>
                <div v-if="erreurlist.quantite" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.quantite)">
                    <span
                      v-for="(e, index) in erreurlist.quantite"
                      :key="index"
                    >
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.quantite }}</span>
                </div>
                <div v-if="erreurlist.type_unite" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.type_unite)">
                    <span
                      v-for="(e, index) in erreurlist.type_unite"
                      :key="index"
                    >
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.type_unite }}</span>
                </div>
                <div v-if="erreurlist.unit_price" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.unit_price)">
                    <span
                      v-for="(e, index) in erreurlist.unit_price"
                      :key="index"
                    >
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.unit_price }}</span>
                </div>
                <div v-if="erreurlist.tva" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.tva)">
                    <span v-for="(e, index) in erreurlist.tva" :key="index">
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.tva }}</span>
                </div>
              </table>
            </div>
          </div>

          <div
            class="groupe_pvw"
            @click="addGroup()"
            :title="$t('INTERVENTION')"
          >
            <font-awesome-icon icon="plus" class="mr-2" />
            {{ $t("INTERVENTION") }}
          </div>

          <div
            class="groupe_pvw"
            :title="$t('LIGNE_LIBRE')"
            @click="addDivers()"
            v-if="!DiverExist"
          >
            <font-awesome-icon icon="plus" class="mr-2" />
            {{ $t("LIGNE_LIBRE") }}
          </div>

          <b-form-group :label="$t('Remise Mode')">
            <b-form-select
              v-model="devisObject.remise_mode"
              :options="optionsRemise"
            ></b-form-select>
          </b-form-group>
          <b-form-group
            :label="$t('REMISE_GLOBALE')"
            v-show="devisObject.remise_mode == 'par_ligne'"
          >
            <div class="remise_block">
              <b-form-input
                v-model="devisObject.remise_value"
                type="number"
                :placeholder="$t('REMISE')"
                :state="validateState('remise_value')"
                class="input placeholder"
                readonly
              ></b-form-input>
            </div>
          </b-form-group>
          <b-form-group
            :label="$t('REMISE_GLOBALE')"
            v-show="devisObject.remise_mode == 'global'"
          >
            <div class="remise_block">
              <b-form-select
                v-model="devisObject.remise_type"
                :options="optionsRemiseType"
                @change="typeRemise"
              ></b-form-select>
              <b-form-input
                v-model="devisObject.remise_value"
                type="number"
                :placeholder="$t('REMISE')"
                :state="validateState('remise_value')"
                class="input placeholder"
                min="0"
                max="100000"
                @keyup="typeRemise"
                step="any"
              ></b-form-input>
            </div>
          </b-form-group>

          <b-form-group :label="$t('REMARQUE')">
            <b-form-textarea
              v-model="devisObject.devis.remarque"
              size="lg"
              :placeholder="$t('REMARQUE')"
              rows="3"
              max-rows="6"
              :state="validateState('remarque')"
              aria-describedby="remarque-feedback"
            ></b-form-textarea>
            <error-handle
              :list="v$.devisObject.devis.remarque.$errors"
              id="remarque-feedback"
            ></error-handle>
            <div v-if="erreurlist.remarque" class="error-message">
              <ul v-if="Array.isArray(erreurlist.remarque)">
                <span v-for="(e, index) in erreurlist.remarque" :key="index">
                  {{ e }}
                </span>
              </ul>
              <span v-else>{{ erreurlist.remarque }}</span>
            </div>
          </b-form-group>

          <b-form-group :label="$t('COND_REG')">
            <b-form-textarea
              v-model="devisObject.devis.conditions_reglements"
              size="lg"
              :placeholder="$t('COND_REG')"
              rows="3"
              max-rows="6"
              :state="validateState('conditions_reglements')"
              aria-describedby="conditions_reglements-feedback"
            ></b-form-textarea>
            <error-handle
              :list="v$.devisObject.devis.conditions_reglements.$errors"
              id="conditions_reglements-feedback"
            ></error-handle>
            <div v-if="erreurlist.conditions_reglements" class="error-message">
              <ul v-if="Array.isArray(erreurlist.conditions_reglements)">
                <span
                  v-for="(e, index) in erreurlist.conditions_reglements"
                  :key="index"
                >
                  {{ e }}
                </span>
              </ul>
              <span v-else>{{ erreurlist.conditions_reglements }}</span>
            </div>
          </b-form-group>

          <b-form-group :label="$t('MOD_PAYMENT')">
            <b-form-select
              v-model="devisObject.devis.moyens_paiement"
              :options="optionspaiement"
              text-field="text"
              value-field="value"
            ></b-form-select>
            <div v-if="erreurlist.moyens_paiement" class="error-message">
              <ul v-if="Array.isArray(erreurlist.moyens_paiement)">
                <span
                  v-for="(e, index) in erreurlist.moyens_paiement"
                  :key="index"
                >
                  {{ e }}
                </span>
              </ul>
              <span v-else>{{ erreurlist.moyens_paiement }}</span>
            </div>
          </b-form-group>

          <div
            v-if="devisObject.devis.moyens_paiement == 'financement'"
            style="width: 100%"
          >
            <b-form-group :label="$t('ORG_PRETEUR')">
              <b-form-input
                v-model="devisObject.devis.organisme_preteur"
                type="text"
                :placeholder="$t('ORG_PRETEUR')"
              ></b-form-input>
              <div v-if="erreurlist.organisme_preteur" class="error-message">
                <ul v-if="Array.isArray(erreurlist.organisme_preteur)">
                  <span
                    v-for="(e, index) in erreurlist.organisme_preteur"
                    :key="index"
                  >
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.organisme_preteur }}</span>
              </div>
            </b-form-group>

            <b-form-group :label="$t('MONTANT_CREDIT')">
              <b-form-input
                v-model="devisObject.devis.montant_credit"
                type="number"
                min="0"
                :placeholder="$t('MONTANT_CREDIT')"
              ></b-form-input>
              <div v-if="erreurlist.montant_credit" class="error-message">
                <ul v-if="Array.isArray(erreurlist.montant_credit)">
                  <span
                    v-for="(e, index) in erreurlist.montant_credit"
                    :key="index"
                  >
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.montant_credit }}</span>
              </div>
            </b-form-group>

            <b-form-group :label="$t('TEAUX_NOMINAL')">
              <b-form-input
                v-model="devisObject.devis.taux_nominal"
                type="number"
                min="0"
                :placeholder="$t('TEAUX_NOMINAL')"
              ></b-form-input>
              <div v-if="erreurlist.taux_nominal" class="error-message">
                <ul v-if="Array.isArray(erreurlist.taux_nominal)">
                  <span
                    v-for="(e, index) in erreurlist.taux_nominal"
                    :key="index"
                  >
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.taux_nominal }}</span>
              </div>
            </b-form-group>

            <b-form-group :label="$t('MENSUALITE')">
              <b-form-input
                v-model="devisObject.devis.mensualite"
                type="number"
                min="0"
                :placeholder="$t('MENSUALITE')"
              ></b-form-input>
              <div v-if="erreurlist.mensualite" class="error-message">
                <ul v-if="Array.isArray(erreurlist.mensualite)">
                  <span
                    v-for="(e, index) in erreurlist.mensualite"
                    :key="index"
                  >
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.mensualite }}</span>
              </div>
            </b-form-group>

            <b-form-group :label="$t('NBR_MENSUALITE')">
              <b-form-input
                v-model="devisObject.devis.nombre_mensualites"
                type="number"
                min="0"
                :placeholder="$t('NBR_MENSUALITE')"
              ></b-form-input>
              <div v-if="erreurlist.nombre_mensualites" class="error-message">
                <ul v-if="Array.isArray(erreurlist.nombre_mensualites)">
                  <span
                    v-for="(e, index) in erreurlist.nombre_mensualites"
                    :key="index"
                  >
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.nombre_mensualites }}</span>
              </div>
            </b-form-group>

            <b-form-group :label="$t('TAEG')">
              <b-form-input
                v-model="devisObject.devis.taeg"
                type="text"
                min="0"
                :placeholder="$t('TAEG')"
              ></b-form-input>
              <div v-if="erreurlist.taeg" class="error-message">
                <ul v-if="Array.isArray(erreurlist.taeg)">
                  <span v-for="(e, index) in erreurlist.taeg" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.taeg }}</span>
              </div>
            </b-form-group>

            <b-form-group :label="$t('CRIDIT_TOTALE')">
              <b-form-input
                v-model="devisObject.devis.cout_total_credit"
                type="number"
                min="0"
                :placeholder="$t('CRIDIT_TOTALE')"
              ></b-form-input>
              <div v-if="erreurlist.cout_total_credit" class="error-message">
                <ul v-if="Array.isArray(erreurlist.cout_total_credit)">
                  <span
                    v-for="(e, index) in erreurlist.cout_total_credit"
                    :key="index"
                  >
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.cout_total_credit }}</span>
              </div>
            </b-form-group>

            <b-form-group :label="$t('ASSURANCE_TYPE')">
              <div class="radio form-check form-check-inline">
                <b-form-radio
                  v-model="devisObject.devis.assurance"
                  name="assurance"
                  value="1"
                  >{{ $t("AVECASSURANCE") }}</b-form-radio
                >
                <b-form-radio
                  v-model="devisObject.devis.assurance"
                  name="assurance"
                  value="0"
                  >{{ $t("SANSASSURANCE") }}</b-form-radio
                >
              </div>
            </b-form-group>

            <b-form-group
              :label="$t('TYPE_ASSURANCE')"
              v-if="devisObject.devis.assurance == '1'"
            >
              <b-form-select
                v-model="devisObject.devis.assurance_type"
                :options="optiontypeassurance"
                text-field="text"
                value-field="value"
              ></b-form-select>
              <div v-if="erreurlist.assurance_type" class="error-message">
                <ul v-if="Array.isArray(erreurlist.assurance_type)">
                  <span
                    v-for="(e, index) in erreurlist.assurance_type"
                    :key="index"
                  >
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.assurance_type }}</span>
              </div>
            </b-form-group>
          </div>
          <b-form-group :label="$t('QUESTION_AIDE')">
            <div class="radio form-check form-check-inline">
              <b-form-radio
                v-model="devisObject.devis.with_aide"
                name="with_aide"
                value="1"
                >{{ $t("OUI") }}</b-form-radio
              >
              <b-form-radio
                v-model="devisObject.devis.with_aide"
                name="with_aide"
                value="0"
                >{{ $t("NON") }}</b-form-radio
              >
            </div>
          </b-form-group>

          <div v-if="devisObject.devis.with_aide == '1'" style="width: 100%">
            <b-form-group :label="$t('MA_PRIME_RENOV')">
              <b-form-input
                v-model="devisObject.devis.montant_maprimrenov"
                type="number"
                min="0"
                :placeholder="$t('MA_PRIME_RENOV')"
                :state="validateState('montant_maprimrenov')"
                aria-describedby="montant_maprimrenov-feedback"
              ></b-form-input>
              <error-handle
                :list="v$.devisObject.devis.montant_maprimrenov.$errors"
                id="montant_maprimrenov-feedback"
              ></error-handle>
              <div v-if="erreurlist.montant_maprimrenov" class="error-message">
                <ul v-if="Array.isArray(erreurlist.montant_maprimrenov)">
                  <span
                    v-for="(e, index) in erreurlist.montant_maprimrenov"
                    :key="index"
                  >
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.montant_maprimrenov }}</span>
              </div>
            </b-form-group>

            <b-form-group :label="$t('MONTANT_CEE')">
              <b-form-input
                v-model="devisObject.devis.montant_cee"
                type="number"
                min="0"
                :placeholder="$t('MONTANT_CEE')"
                :state="validateState('montant_cee')"
                aria-describedby="montant_cee-feedback"
              ></b-form-input>
              <error-handle
                :list="v$.devisObject.devis.montant_cee.$errors"
                id="montant_cee-feedback"
              ></error-handle>
              <div v-if="erreurlist.montant_cee" class="error-message">
                <ul v-if="Array.isArray(erreurlist.montant_cee)">
                  <span
                    v-for="(e, index) in erreurlist.montant_cee"
                    :key="index"
                  >
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.montant_cee }}</span>
              </div>
            </b-form-group>
            <b-form-group :label="$t('BaremeMPR')">
              <b-form-select
                v-model="devisObject.devis.bareme_mpr"
                :options="Barememproption"
                :state="validateState('bareme_mpr')"
                aria-describedby="bareme_mpr-feedback"
              ></b-form-select>
              <error-handle
                :list="v$.devisObject.devis.bareme_mpr.$errors"
                id="bareme_mpr-feedback"
              ></error-handle>
              <div v-if="erreurlist.bareme_mpr" class="error-message">
                <ul v-if="Array.isArray(erreurlist.bareme_mpr)">
                  <span
                    v-for="(e, index) in erreurlist.bareme_mpr"
                    :key="index"
                  >
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.bareme_mpr }}</span>
              </div>
            </b-form-group>
          </div>
        </div>
      </form>
      <template #modal-footer>
        <b-alert variant="warning" show v-if="errorU">
          {{ errorU }}
        </b-alert>
        <div class="double">
          <b-button variant="success" @click="editDevis">
            <div class="block-spinner">
              {{ $t("CONFIRM") }}
              <div v-if="getdevisLoading" class="loading ml-2">
                <div class="spinner-border" role="status"></div>
              </div>
            </div>
          </b-button>
          <b-button variant="danger" @click="resetModal()">
            {{ $t("CANCEL") }}
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal id="produitModal" ref="produitModal" hide-footer>
      <template #modal-header>
        <h5>{{ $t("CHOOSE") }}</h5>

        <b-button size="sm" @click="hideModal('produitModal')">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17.028"
            height="17.028"
            viewBox="0 0 17.028 17.028"
          >
            <path
              id="Icon_material-close"
              data-name="Icon material-close"
              d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
              transform="translate(-7.5 -7.5)"
              fill="#393939"
            />
          </svg>
        </b-button>
      </template>
      <form @submit.prevent="hideModal('produitModal')" v-if="devisObject">
        <b-tabs content-class="tabs" justified v-model="tabIndex">
          <b-tab title="First" active lazy @click="loadProduit">
            <template #title>
              <b-spinner
                type="border"
                small
                v-if="getProductLoading"
              ></b-spinner>
              {{ $t("TypePrestation.Fourniture") }}
            </template>
            <div class="center">
              <searchInput
                :list="getAllProducts"
                :loader="getProductLoading"
                label="lib"
                :searchFunc="all_products"
                @searchfilter="addProduit($event, 'produit')"
                modalename="article"
                :filtre="{
                  entreprise: devisObject.entreprise.id,
                }"
              ></searchInput>
            </div>
          </b-tab>
          <b-tab title="Second" lazy @click="loadForfait">
            <template #title>
              <b-spinner
                type="border"
                small
                v-if="getforfaitLoading"
              ></b-spinner>
              {{ $t("TypePrestation.Forfait") }}
            </template>
            <div class="center">
              <searchInput
                :list="getAllforfaits"
                :loader="getforfaitLoading"
                label="lib"
                :searchFunc="all_forfaits"
                :filtre="{
                  entreprise: devisObject.entreprise.id,
                }"
                @searchfilter="addProduit($event, 'forfait')"
                modalename="Forfait"
              ></searchInput>
            </div>
          </b-tab>
          <b-tab title="Third" lazy @click="loadMainOeuvre">
            <template #title>
              <b-spinner
                type="border"
                small
                v-if="getmainoeuvreLoading"
              ></b-spinner>
              {{ $t("TypePrestation.MainOeuvre") }}
            </template>
            <div class="center">
              <searchInput
                :list="getAllmainoeuvres"
                :loader="getmainoeuvreLoading"
                label="lib"
                :searchFunc="all_mainoeuvres"
                :filtre="{
                  entreprise: devisObject.entreprise.id,
                }"
                @searchfilter="addProduit($event, 'mainoeuvre')"
                modalename="MainOeuvre"
              ></searchInput>
            </div>
          </b-tab>
        </b-tabs>

        <div class="actionModel">
          <b-button variant="success" @click="hideModal('produitModal')">
            {{ $t("CONFIRM") }}
          </b-button>
        </div>
      </form>
    </b-modal>

    <b-modal id="pieceModal" ref="pieceModal" hide-footer>
      <template #modal-header>
        <h5>{{ $t("CHOOSE") }} {{ $t("ROOM") }}</h5>
        <b-button size="sm" @click="hideModal('pieceModal')">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17.028"
            height="17.028"
            viewBox="0 0 17.028 17.028"
          >
            <path
              id="Icon_material-close"
              data-name="Icon material-close"
              d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
              transform="translate(-7.5 -7.5)"
              fill="#393939"
            />
          </svg>
        </b-button>
      </template>
      <form @submit.prevent="hideModal('pieceModal')" v-if="devisObject">
        <div class="center">
          <b-form-group>
            <searchInput
              :list="getAllpiece"
              :loader="getLoadingpiece"
              label="lib"
              :searchFunc="allpiece"
              @searchfilter="addPiece($event)"
              modalename="piece"
              :filtre="{ entreprise: devisObject.entreprise.id }"
              :with_default="1"
              :with_default_lib="$t('Afficher les pieces publiques')"
            >
            </searchInput>
          </b-form-group>
        </div>
        <div class="actionModel">
          <b-button variant="success" type="submit">
            {{ $t("CONFIRM") }}
          </b-button>
        </div>
      </form>
    </b-modal>

    <b-modal id="travauxModal" ref="travauxModal" hide-footer>
      <template #modal-header>
        <h5>{{ $t("CHOOSE") }} {{ $t("WORK") }}</h5>
        <b-button size="sm" @click="hideModal('travauxModal')">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17.028"
            height="17.028"
            viewBox="0 0 17.028 17.028"
          >
            <path
              id="Icon_material-close"
              data-name="Icon material-close"
              d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
              transform="translate(-7.5 -7.5)"
              fill="#393939"
            />
          </svg>
        </b-button>
      </template>
      <form @submit.prevent="hideModal('travauxModal')" v-if="devisObject">
        <div class="center">
          <b-form-group>
            <searchInput
              :list="getAlltravaux"
              :loader="getLoadingtravaux"
              label="lib"
              :searchFunc="alltravaux"
              @searchfilter="addTravaux($event)"
              modalename="traveaux"
              :filtre="{ entreprise: devisObject.entreprise.id }"
              :with_default="1"
              :with_default_lib="$t('Afficher les travaux publiques')"
            >
            </searchInput>
          </b-form-group>
        </div>

        <div class="actionModel">
          <b-button variant="success" type="submit">
            {{ $t("CONFIRM") }}
          </b-button>
        </div>
      </form>
    </b-modal>

    <b-modal id="sousTraitantModal" ref="sousTraitantModal" hide-footer>
      <template #modal-header="{ close }">
        <h5>{{ $t("CHOOSE") }} {{ $t("SOUS-TRAITANT") }}</h5>
        <b-button size="sm" @click="close()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17.028"
            height="17.028"
            viewBox="0 0 17.028 17.028"
          >
            <path
              id="Icon_material-close"
              data-name="Icon material-close"
              d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
              transform="translate(-7.5 -7.5)"
              fill="#393939"
            />
          </svg>
        </b-button>
      </template>
      <form @submit.prevent="hideModal('sousTraitantModal')" v-if="devisObject">
        <div class="center">
          <b-form-group>
            <searchInput
              :list="getAllsousTraitant"
              :loader="getLoadingsousTraitant"
              label="name"
              :searchFunc="allsousTraitant"
              @searchfilter="addSousTraitant($event)"
              :filtre="{ entreprise: devisObject.entreprise.id }"
              :with_default="0"
            >
            </searchInput>
          </b-form-group>
        </div>

        <div class="actionModel">
          <b-button variant="success" type="submit">
            {{ $t("CONFIRM") }}
          </b-button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  maxLength,
  minLength,
  requiredIf,
  numeric,
} from "@vuelidate/validators";
import errorHandle from "./errorHandle.vue";

export default {
  setup() {
    return { v$: useVuelidate({ $lazy: true, $autoDirty: true }) };
  },
  components: {
    searchInput: () => import("@/components/ui/searchInput"),
    errorHandle,
  },
  data() {
    return {
      erreurlist: {
        description: null,
        interlocuteur_id: null,
        prime: null,
        ref: null,
        lib: null,
        desc: null,
        quantite: null,
        type_unite: null,
        unit_price: null,
        tva: null,
        remarque: null,
        conditions_reglements: null,
        moyens_paiement: null,
      },
      devisObject: null,
      errorU: null,
      group_to_add: [],
      group_to_delete: [],
      product_to_delete: [],
      product_to_add: [],
      optionsRemiseType: [
        { value: "remise.HT", text: "€" },
        { value: "remise.pourcentage", text: "%" },
      ],
      optionsprime: [
        { value: "renovation_globale", text: this.$t("renovation_globale") },
        {
          value: "renovation_energetique",
          text: this.$t("renovation_energetique"),
        },
        { value: "devis_pv", text: this.$t("devis_pv") },
        { value: "devis_libre", text: this.$t("devis_libre") },
      ],
      optionspaiement: [
        {
          value: "cheque_virement_espece",
          text: this.$t("Cheque_virement_espece"),
        },
        { value: "financement", text: this.$t("FINANCEMENT") },
      ],
      optiontypeassurance: [
        { value: "deces_invalidite", text: "Décés  + Invalidité" },
        { value: "perte_emploi", text: "Perte d'emploi" },
        {
          value: "deces_invalidite_perte_emploi",
          text: "Décés + Invalidité + Perte d'emploi",
        },
      ],
      Barememproption: [
        { value: null, text: "Aucun" },
        { value: "blue", text: this.$t("BLEU") },
        { value: "yellow", text: this.$t("YELLOW") },
        { value: "purple", text: this.$t("PURPLE") },
        { value: "pink", text: this.$t("PINK") },
      ],

      showGroup: [
        {
          bool: true,
        },
      ],
      optionsRemise: [
        { value: "par_ligne", text: this.$t("REMISE_LIGNE") },
        { value: "global", text: this.$t("REMISE_GLOBALE") },
      ],
      optionsTVA: [
        { value: 0, text: "0%" },
        { value: 5.5, text: "5.5%" },
        { value: 10, text: "10%" },
        { value: 20, text: "20%" },
      ],
      optionstype: [
        { value: "m2", text: "m2" },
        { value: "ml", text: "ML" },
        { value: "h", text: "h" },
        { value: "forfait", text: this.$t("PACKAGE") },
        { value: "unitaire", text: this.$t("unit_unitaire") },
      ],
      nbreAjout: 0,
      save: 0,
      currentGroup: [],
      box: "",
      tabIndex: 0,
      errorProduits: [],
    };
  },
  validations() {
    return {
      devisObject: {
        devis: {
          remise_value: { numeric },
          description: { maxLength: maxLength(1000) },
          remarque: { maxLength: maxLength(1000) },
          conditions_reglements: { maxLength: maxLength(1000) },
          montant_maprimrenov: {
            requiredIf: requiredIf(
              this.avecAide && !this.devisObject.devis.montant_cee
            ),
          },
          montant_cee: {
            requiredIf: requiredIf(
              this.avecAide && !this.devisObject.devis.montant_maprimrenov
            ),
          },
          bareme_mpr: {
            requiredIf: requiredIf(
              this.avecAide &&
                this.devisObject &&
                this.devisObject.devis.montant_maprimrenov > 0
            ),
          },
          rue_travaux: { maxLength: maxLength(100) },
          cp_travaux: {
            numeric,
            minLength: minLength(4),
            maxLength: maxLength(5),
          },
          ville_travaux: { maxLength: maxLength(100) },
        },
      },
      produit: {
        lib: { required },
      },
    };
  },

  props: {
    devis: Object,
  },

  methods: {
    ...mapActions([
      "updatedevis",
      "all_products",
      "all_forfaits",
      "all_mainoeuvres",
      "alltravaux",
      "allpiece",
      "getonedevis",
      "allprestation",
      "update_devis_group",
      "allsousTraitant",
    ]),

    validateState(name) {
      const { $dirty, $error } = this.v$.devisObject.devis[name];
      return $dirty ? !$error : null;
    },

    resetGlobal(reset) {
      if (reset) {
        this.devisObject.remise_value = 0;
        this.devisObject.remise_mode = "par_ligne";
        this.recalculerTotal();
      }
    },

    typeRemise() {
      this.devisObject.GroupeLigneDocument_produit.forEach((product) => {
        product.remise_value = 0;
        product.remise_total = 0;
        product.remise_type = this.devisObject.remise_type;
        this.editProduct(product, false);
      });
      this.devisObject.remise_mode = "global";
      this.$forceUpdate();
    },

    recalculerTotal() {
      var totalRemise = 0;
      this.devisObject.GroupeLigneDocument_produit.forEach((product) => {
        let ht = product.unit_price * product.quantite;
        if (product.remise_type == "remise.HT")
          totalRemise += product.remise_value ? +product.remise_value : 0;
        else {
          totalRemise += (product.remise_value * ht) / 100;
        }
      });

      this.devisObject.remise_value = Math.round(totalRemise * 100) / 100;

      this.$forceUpdate();
    },

    resetModal(refresh = true) {
      this.erreurlist = {
        description: null,
        interlocuteur_id: null,
        prime: null,
        ref: null,
        lib: null,
        desc: null,
        quantite: null,
        type_unite: null,
        unit_price: null,
        tva: null,
        remarque: null,
        conditions_reglements: null,
        moyens_paiement: null,
        montant_credit: null,
        organisme_preteur: null,
        taux_nominal: null,
        delegataire_id: null,
        cout_total_credit: null,
        taeg: null,
        nombre_mensualites: null,
        mensualite: null,
        bareme_mpr: null,
        montant_cee: null,
        montant_maprimrenov: null,
        assurance_type: null,
      };
      this.errorU = null;
      this.group_to_add = [];
      this.group_to_delete = [];
      this.$refs["devisModal"].hide();
      if (refresh) this.$emit("closeUpdate");
    },

    cleanGroup() {
      this.devisObject.groupeLigneDocument.forEach((group) => {
        if (!Number.isInteger(group.id)) {
          group.produits = [];
          this.devisObject.GroupeLigneDocument_produit.forEach((p) => {
            if (p.groupelignedocument_id == group.id) {
              p.produit_id = p.id;
              group.produits.push(p);
            }
          });
          this.group_to_add.push(group);
        }
      });
    },
    async cleanProduct() {
      this.devisObject.groupeLigneDocument.forEach((group) => {
        if (Number.isInteger(group.id)) {
          group.product_to_add = [];
          group.product_to_delete = [];
          this.product_to_delete.forEach((p) => {
            if (p.id == group.id) {
              group.product_to_delete.push(p.p);
            }
          });
          this.product_to_add.forEach((p) => {
            if (p.groupelignedocument_id == group.id) {
              group.product_to_add.push(p);
            }
          });
        }
      });

      var list = this.devisObject.groupeLigneDocument.filter((l) =>
        Number.isInteger(l.id)
      );

      list.forEach((group) => {
        if (group.sous_traitant)
          group.sous_traitant_id = group.sous_traitant.id;
      });

      const status = await this.update_devis_group(list);
      if (status != true) {
        this.errorU = status;
        return false;
      }
      return true;
    },

    editProduct(product, reset = false) {
      this.product_to_add = this.product_to_add.filter(
        (p) => p.id != product.id
      );
      this.product_to_delete = this.product_to_delete.filter(
        (p) => p.p != product.id
      );

      this.product_to_add.push(product);
      this.product_to_delete.push({
        id: product.groupelignedocument_id,
        p: product.id,
      });
      this.resetGlobal(reset);
    },

    prixHT() {
      var ht = 0;
      this.devisObject.GroupeLigneDocument_produit.forEach((product) => {
        ht += +product.quantite * +product.unit_price;
      });
      return Math.round(ht * 100) / 100;
    },

    async checkProduits() {
      var error = true;
      for (
        let i = 0;
        i < this.devisObject.GroupeLigneDocument_produit.length;
        i++
      ) {
        if (
          !this.devisObject.GroupeLigneDocument_produit[i].lib ||
          this.devisObject.GroupeLigneDocument_produit[i].lib.length > 1000
        ) {
          this.errorProduits[i] = true;
          error = false;
        } else if (
          !this.devisObject.GroupeLigneDocument_produit[i].ref ||
          this.devisObject.GroupeLigneDocument_produit[i].ref.length > 1000
        ) {
          this.errorProduits[i] = true;
          error = false;
        } else if (
          this.devisObject.GroupeLigneDocument_produit[i].quantite < 1 ||
          this.devisObject.GroupeLigneDocument_produit[i].quantite > 10000000 ||
          isNaN(this.devisObject.GroupeLigneDocument_produit[i].quantite)
        ) {
          this.errorProduits[i] = true;
          error = false;
        } else if (
          this.devisObject.GroupeLigneDocument_produit[i].unit_price <
            -1000000000 ||
          this.devisObject.GroupeLigneDocument_produit[i].unit_price >
            1000000000 ||
          isNaN(this.devisObject.GroupeLigneDocument_produit[i].unit_price)
        ) {
          this.errorProduits[i] = true;
          error = false;
        } else this.errorProduits[i] = false;
      }

      return error;
    },

    async editDevis() {
      const isFormCorrect = await this.v$.devisObject.$error;
      if (isFormCorrect) return;
      const resultP = await this.checkProduits();
      if (!resultP) {
        this.box = "";
        this.$bvModal.msgBoxOk("Remplir correctement le formulaire", {
          title: "Confirmation",
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          headerClass: "p-2 border-bottom-0",
          centered: true,
        });
        this.devisObject.groupeLigneDocument.push({});
        this.devisObject.groupeLigneDocument.pop();
        return;
      }
      const result = await this.cleanProduct();
      if (!result) return;

      this.cleanGroup();
      this.erreurlist = {
        description: null,
        interlocuteur_id: null,
        prime: null,
        ref: null,
        lib: null,
        desc: null,
        quantite: null,
        type_unite: null,
        unit_price: null,
        tva: null,
        remarque: null,
        conditions_reglements: null,
        moyens_paiement: null,
        montant_credit: null,
        organisme_preteur: null,
        taux_nominal: null,
        delegataire_id: null,
        cout_total_credit: null,
        taeg: null,
        nombre_mensualites: null,
        mensualite: null,
        bareme_mpr: null,
        montant_cee: null,
        montant_maprimrenov: null,
        assurance_type: null,
      };
      this.errorU = null;
      var devis = {
        id: this.devis.devis.id,
        client_id: this.devis.client.id,
        modele_id: this.devis.devis.modele.id,
        groups_to_add: this.group_to_add,
        groups_to_delete: this.group_to_delete,
      };
      if (this.devisObject.devis.debut_devis) {
        devis.debut_devis = new Date(this.devisObject.devis.debut_devis)
          .toLocaleDateString("fr-CA", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })
          .replace(/-/g, "/");
      }
      if (this.devisObject.devis.prime)
        devis.prime = this.devisObject.devis.prime;

      if (this.devisObject.remise_mode)
        devis.remise_mode = this.devisObject.remise_mode;

      if (this.devisObject.remise_value) {
        devis.remise_type = this.devisObject.remise_type;
        devis.remise_value = this.devisObject.remise_value;
        if (this.devisObject.remise_type == "remise.HT")
          devis.remise_total = devis.remise_value;
        else devis.remise_total = (devis.remise_value * this.prixHT()) / 100;
      } else {
        devis.remise_type = this.devisObject.remise_type;
        devis.remise_value = this.devisObject.remise_value;
        if (this.devisObject.remise_type == "remise.HT") {
          devis.remise_total = 0;
          this.devisObject.GroupeLigneDocument_produit.forEach((product) => {
            product.remise_total = +product.remise_value;
            devis.remise_total += +product.remise_value;
          });
        } else {
          devis.remise_total = 0;
          this.devisObject.GroupeLigneDocument_produit.forEach((product) => {
            product.remise_total =
              (+product.quantite *
                +product.unit_price *
                +product.remise_value) /
              100;
            devis.remise_total += Math.round(+product.remise_total * 100) / 100;
          });
        }
      }

      if (
        this.devisObject.devis.prime != "devis_libre" &&
        this.devisObject.devis.interlocuteur
      )
        devis.interlocuteur_id = this.devisObject.devis.interlocuteur.id;
      if (this.devisObject.devis.description)
        devis.description = this.devisObject.devis.description;
      if (this.devisObject.devis.visite_technique)
        devis.visite_technique = this.devisObject.devis.visite_technique;
      if (this.devisObject.devis.fin_devis)
        devis.fin_devis = this.devisObject.devis.fin_devis;

      if (this.devisObject.devis.same_travaux_address) {
        devis.same_travaux_address = 1;
        // devis.rue_travaux = this.devisObject.client.rue;
        // devis.cp_travaux = this.devisObject.client.cp;
        // devis.ville_travaux = this.devisObject.client.ville;
      } else {
        devis.same_travaux_address = 0;
        if (this.devisObject.devis.rue_travaux) {
          devis.rue_travaux = this.devisObject.devis.rue_travaux;
        }
        if (this.devisObject.devis.cp_travaux) {
          devis.cp_travaux = this.devisObject.devis.cp_travaux;
        }
        if (this.devisObject.devis.ville_travaux) {
          devis.ville_travaux = this.devisObject.devis.ville_travaux;
        }
      }

      if (this.devisObject.devis.remarque)
        devis.remarque = this.devisObject.devis.remarque;
      if (this.devisObject.devis.conditions_reglements)
        devis.conditions_reglements =
          this.devisObject.devis.conditions_reglements;
      if (this.devisObject.devis.moyens_paiement)
        devis.moyens_paiement = this.devisObject.devis.moyens_paiement;
      else devis.moyens_paiement = "cheque_virement_espece";

      if (this.devisObject.devis.delegataire)
        devis.delegataire_id = this.devisObject.devis.delegataire.id;

      if (this.devisObject.devis.moyens_paiement == "financement") {
        if (this.devisObject.devis.organisme_preteur)
          devis.organisme_preteur = this.devisObject.devis.organisme_preteur;
        if (this.devisObject.devis.montant_credit)
          devis.montant_credit = this.devisObject.devis.montant_credit;
        if (this.devisObject.devis.taux_nominal)
          devis.taux_nominal = this.devisObject.devis.taux_nominal;
        if (this.devisObject.devis.mensualite)
          devis.mensualite = this.devisObject.devis.mensualite;
        if (this.devisObject.devis.nombre_mensualites)
          devis.nombre_mensualites = this.devisObject.devis.nombre_mensualites;
        if (this.devisObject.devis.taeg)
          devis.taeg = this.devisObject.devis.taeg;
        if (this.devisObject.devis.cout_total_credit)
          devis.cout_total_credit = this.devisObject.devis.cout_total_credit;
        if (this.devisObject.devis.assurance)
          devis.assurance = this.devisObject.devis.assurance;
        else {
          devis.assurance = 0;
        }
        if (this.devisObject.devis.assurance) {
          if (this.devisObject.devis.assurance_type)
            devis.assurance_type = this.devisObject.devis.assurance_type;
        } else {
          devis.assurance_type = null;
        }
      }

      if (this.devisObject.devis.with_aide)
        devis.with_aide = this.devisObject.devis.with_aide;
      else {
        devis.with_aide = 0;
      }

      if (+this.devisObject.devis.montant_maprimrenov && devis.with_aide) {
        devis.montant_maprimrenov = this.devisObject.devis.montant_maprimrenov;
      } else {
        devis.montant_maprimrenov = 0;
      }
      if (+this.devisObject.devis.montant_cee && devis.with_aide) {
        devis.montant_cee = this.devisObject.devis.montant_cee;
      } else {
        devis.montant_cee = 0;
      }
      if (this.devisObject.devis.bareme_mpr && devis.with_aide) {
        devis.bareme_mpr = this.devisObject.devis.bareme_mpr;
      } else {
        devis.bareme_mpr = null;
      }

      this.updatedevis(devis)
        .then(() => {
          this.resetModal(false);
          this.$emit("updateList");
        })
        .catch((err) => {
          if (this.isObject(err)) {
            for (const [key, value] of Object.entries(err)) {
              if (!this.erreurlist[key]) {
                this.erreurlist[key] = value;
              }
            }
          } else {
            this.errorU = err;
          }
        });
    },

    isObject(obj) {
      return Object.prototype.toString.call(obj) === "[object Object]";
    },
    closeGroup(i) {
      this.showGroup[i].bool = !this.showGroup[i].bool;
    },

    add_to_trash(group, i) {
      if (this.devisObject.groupeLigneDocument.length > 1) {
        this.group_to_delete.push(group.id);
        this.devisObject.groupeLigneDocument.splice(i, 1);
        this.showGroup.pop();
      } else {
        this.box = "";
        this.$bvModal.msgBoxOk("Devis doit contenir au moins un groupe", {
          title: "Confirmation",
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          headerClass: "p-2 border-bottom-0",
          centered: true,
        });
      }
    },

    addProduit(item, type) {
      var produit = {};
      var find = this.devisObject.GroupeLigneDocument_produit.findIndex(
        (element) => element.id == item.id
      );

      if (find < 0) {
        item.remise_value = 0;
        item.remise_type = this.devisObject.remise_type;
        item.quantite = 1;
        item.quantite = 1;
        item.type_ligne = type;
        item.groupelignedocument_id = this.currentGroup.id;
        this.devisObject.GroupeLigneDocument_produit.push(item);
        this.product_to_add.push(item);
      } else {
        produit = this.devisObject.GroupeLigneDocument_produit[find];
        this.devisObject.GroupeLigneDocument_produit.splice(find, 1);
        produit.quantite++;
        this.devisObject.GroupeLigneDocument_produit.splice(find, 0, produit);
      }
      this.$refs["produitModal"].hide();
    },

    addJokerProduit(group) {
      var isCorrect = this.verifProduct(group);
      if (!isCorrect) return;

      var item = {
        groupelignedocument_id: group.id,
        lib: "",
        desc: "",
        ref: "",
        tva: 0,
        quantite: 1,
        unit_price: 0,
        type_unite: "unitaire",
        type_ligne: "joker",
      };
      this.devisObject.GroupeLigneDocument_produit.push(item);
      this.product_to_add.push(item);
    },

    popProduit(group, prod) {
      var count = this.devisObject.GroupeLigneDocument_produit.filter(
        (p) => p.groupelignedocument_id == group.id
      ).length;

      if (count > 1) {
        this.devisObject.GroupeLigneDocument_produit =
          this.devisObject.GroupeLigneDocument_produit.filter(
            (element) => element.id != prod.id
          );
        this.product_to_delete.push({
          id: group.id,
          p: prod.id,
        });
      } else {
        this.box = "";
        this.$bvModal.msgBoxOk("Groupe doit contenir au moins un produit", {
          title: "Confirmation",
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          headerClass: "p-2 border-bottom-0",
          centered: true,
        });
      }
    },

    loadProduit() {
      const data = {
        page: 1,
        per_page: 1000,
        entreprise: this.devisObject.devis.entreprise.id,
      };
      this.all_products(data);
    },

    loadForfait() {
      const data = {
        page: 1,
        per_page: 1000,
        entreprise: this.devisObject.devis.entreprise.id,
      };
      this.all_forfaits(data);
    },

    loadMainOeuvre() {
      const data = {
        page: 1,
        per_page: 1000,
        entreprise: this.devisObject.devis.entreprise.id,
      };
      this.all_mainoeuvres(data);
    },

    verifProduct(group) {
      var products = this.devisObject.GroupeLigneDocument_produit.filter(
        (p) => p.groupelignedocument_id == group.id
      );

      if (!products.length) return true;

      var product = products[products.length - 1];

      if (!product) return true;

      if (product.lib == "" || product.ref == "" || product.quantite < 1) {
        this.box = "";
        this.$bvModal.msgBoxOk("Remplissez correctement le produit précedant", {
          title: "Confirmation",
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          headerClass: "p-2 border-bottom-0",
          centered: true,
        });
        return false;
      }

      return true;
    },

    openproduit(group, i) {
      var isCorrect = this.verifProduct(group);
      if (!isCorrect) return;

      this.loadProduit();
      this.saveGroup(i);
      this.currentGroup = group;

      this.allprestation({
        page: 1,
        per_page: 1000,
        entreprise: this.devisObject.devis.entreprise.id,
      });
      this.$refs["produitModal"].show();
    },

    creategroup() {
      this.showGroup = [];
      this.devisObject.groupeLigneDocument.forEach(() => {
        this.showGroup.push({
          bool: true,
        });
      });
    },
    saveGroup(i) {
      this.save = i;
    },

    openPiece(i) {
      this.allpiece({
        entreprise: this.devisObject.devis.entreprise.id,
        page: 1,
        per_page: 1000,
      }).then(() => {
        this.saveGroup(i);
        this.$refs["pieceModal"].show();
      });
    },

    openTravaux(i) {
      this.alltravaux({
        entreprise: this.devisObject.devis.entreprise.id,
        page: 1,
        per_page: 1000,
      }).then(() => {
        this.saveGroup(i);
        this.$refs["travauxModal"].show();
      });
    },

    opensousTraitant(i) {
      this.allsousTraitant({
        entreprise: this.devisObject.devis.entreprise.id,
        page: 1,
        per_page: 1000,
      }).then(() => {
        this.saveGroup(i);
        this.$refs["sousTraitantModal"].show();
      });
    },

    addTravaux(item) {
      this.devisObject.groupeLigneDocument[this.save].traveaux = item.lib;
      this.devisObject.groupeLigneDocument[this.save].type_traveaux_id =
        item.id;
      this.$refs["travauxModal"].hide();
    },

    addSousTraitant(item) {
      this.devisObject.groupeLigneDocument[this.save].sous_traitant = item;
      this.devisObject.groupeLigneDocument[this.save].sous_traitant_id =
        item.id;
      this.$refs["sousTraitantModal"].hide();
      this.$forceUpdate();
    },

    deleteSousTraitant(i) {
      this.devisObject.groupeLigneDocument[i].sous_traitant = null;
      this.devisObject.groupeLigneDocument[i].sous_traitant_id = null;
      this.$refs["sousTraitantModal"].hide();
      this.$forceUpdate();
    },

    addPiece(item) {
      this.devisObject.groupeLigneDocument[this.save].piece = item.lib;
      this.devisObject.groupeLigneDocument[this.save].type_piece_id = item.id;
      this.$refs["pieceModal"].hide();
    },

    hideModal(ref) {
      this.$refs[ref].hide();
    },

    verifGroup() {
      var groupe =
        this.devisObject.groupeLigneDocument[
          this.devisObject.groupeLigneDocument.length - 1
        ];
      if (
        !groupe.piece ||
        groupe.piece == "" ||
        (!groupe.traveaux && groupe.piece != "Divers")
      ) {
        this.box = "";
        this.$bvModal.msgBoxOk("Remplissez correctement le groupe précedant", {
          title: "Confirmation",
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          headerClass: "p-2 border-bottom-0",
          centered: true,
        });
        return false;
      }

      this.showGroup.push({
        bool: true,
      });
      return true;
    },

    addGroup() {
      var isCorrect = this.verifGroup();
      if (!isCorrect) return;

      var group = {
        id: `new${this.nbreAjout}`,
        document_id: this.devisObject.id,
        piece: null,
        traveaux: null,
      };
      this.nbreAjout++;
      this.devisObject.groupeLigneDocument.push(group);
    },

    addDivers() {
      var isCorrect = this.verifGroup();
      if (!isCorrect) return;

      var group = {
        id: `new${this.nbreAjout}`,
        document_id: this.devisObject.id,
        piece: "Divers",
        traveaux: null,
      };
      this.nbreAjout++;
      this.devisObject.groupeLigneDocument.push(group);
    },
  },

  computed: {
    ...mapGetters([
      "getAllUsers",
      "getAllDelegataire",
      "getdevisLoading",
      "getAllProducts",
      "getProductLoading",
      "getAllforfaits",
      "getforfaitLoading",
      "getAllmainoeuvres",
      "getmainoeuvreLoading",
      "getAllpiece",
      "getLoadingpiece",
      "getAlltravaux",
      "getLoadingtravaux",
      "getdevis",
      "getAllsousTraitant",
      "getLoadingsousTraitant",
    ]),
    isSuperAdmin() {
      return this.getOnlineUser.role == "user.super-admin";
    },
    isentrepriseAdmin() {
      return this.getOnlineUser.role == "user.entreprise-admin";
    },
    DiverExist() {
      var count = this.devisObject.groupeLigneDocument.filter(
        (g) => g.piece == "Divers"
      ).length;

      if (count > 0) return true;
      else return false;
    },
    avecAide() {
      var aide = false;
      if (this.devisObject) {
        if (
          this.devisObject.devis.with_aide == 1 ||
          this.devisObject.devis.with_aide == "1"
        )
          aide = true;
      }

      return aide;
    },
  },

  mounted() {
    this.devisObject = this.devis;
    
    this.devisObject.remise_type = this.devisObject.remise_type
      ? this.devisObject.remise_type
      : "remise.HT";
    this.devisObject.remise_value = this.devisObject.remise_value
      ? this.devisObject.remise_value
      : 0;
    this.devisObject.devis.assurance = this.devisObject.devis.assurance ? 1 : 0;
    this.devisObject.devis.with_aide = this.devisObject.devis.with_aide ? 1 : 0;

    if (this.devisObject.devis.visite_technique) {
      this.devisObject.devis.visite_technique = new Date(
        this.devisObject.devis.visite_technique
      ).toLocaleDateString("fr-CA");
    }
    if (this.devisObject.devis.debut_devis) {
      this.devisObject.devis.debut_devis = new Date(
        this.devisObject.devis.debut_devis
      ).toLocaleDateString("fr-CA");
    }

    if (this.devisObject.devis.fin_devis) {
      this.devisObject.devis.fin_devis = new Date(
        this.devisObject.devis.fin_devis
      ).toLocaleDateString("fr-CA");
    }

    this.$refs["devisModal"].show();
    this.creategroup();
  },
};
</script>

<style lang="scss" scoped>
.modal-body form .form-group {
  max-width: none;
}

.spinner-border {
  width: 1.5rem;
  height: 1.5rem;
}
.group {
  @media only screen and (max-width: 1000px) {
    overflow: hidden;
    padding: 8px;
  }
  box-shadow: 0px 2px 6px #00000019;
  background-color: #fff;
  border-radius: 0 0 5px 5px;
  margin-bottom: 15px;
  .part {
    overflow: auto;
    padding: 15px;
    margin-bottom: 0;
    ul {
      @media only screen and (max-width: 1000px) {
        flex-direction: column;
      }
      display: flex;
      align-items: center;
    }
  }
}

ul {
  list-style: none;

  li {
    @media only screen and (max-width: 1000px) {
      justify-content: space-between;
    }
    display: flex;
    align-items: center;
    color: #515151;

    span {
      display: inline-block;
      font-size: 14px;
      white-space: nowrap;
    }

    .icons,
    .iconsred {
      cursor: pointer;
      background-color: #28367a;
      color: #fff;
      padding: 2px 4px;
      font-size: 12px;
      border-radius: 4px;
      vertical-align: middle;
    }

    .iconsred {
      background-color: #e4261b;
    }

    .title {
      @media only screen and (max-width: 900px) {
        font-size: 12px;
      }
      color: #393939;
      font-weight: 600;
      font-size: 14px;
      margin-right: 10px;
    }
  }

  .traveauxtitle {
    @media only screen and (max-width: 900px) {
      padding: 0;
    }
    padding: 0 15px;
    display: flex;
    align-items: center;
    gap: 4px;
  }
}
.piece {
  background-color: #28367a;
  color: #fff;
  padding: 0px 15px;
  border-radius: 5px 5px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    margin: 0;

    .edit {
      cursor: pointer;
      background-color: #e4261b;
      color: #fff;
      padding: 2px 4px;
      font-size: 12px;
      border-radius: 4px;
      vertical-align: middle;
    }
  }

  div:first-child {
    display: flex;
    align-items: center;
  }
  .icone-down-up {
    display: flex;
    align-items: center;
    p,
    span {
      @media only screen and (max-width: 1000px) {
        display: none;
      }
    }

    button {
      background-color: transparent !important;
      border: transparent;
      box-shadow: none;
    }

    span {
      font-size: 14px;
      background-color: #6472b3;
      border-radius: 4px;
      padding: 2px 12px;
    }
  }
}

.table {
  thead th {
    padding: 4px;
  }

  tbody td {
    vertical-align: middle;
  }
}

.groupe_pvw {
  margin-bottom: 12px;
  border: 1px dotted #28367a;
  width: 100%;
  padding: 4px 8px;
  font-size: 14px;
  color: #28367a;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #28367a;
    color: #fff;
  }
}

.detail_pvw {
  background-color: rgba(#000, 0.1);
  border-radius: 5px;
  width: 100%;
  height: 30px;
}

.del {
  font-size: 14px;
  padding: 4px;
  color: #fff;
  border: 1px solid #e4261b;
  background-color: #e4261b;
  border-radius: 5px;
}
.add {
  width: 100%;
  height: 32px;
  border: 1px solid #28367a;
  background-color: #28367a;
  border-radius: 5px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  .icons {
    font-size: 14px;
  }
}

.tabs {
  .spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.2em;
  }
}

.address {
  text-align: center;
  color: #28367a;
  font-size: 12px;
}

.errorBG {
  background-color: rgba(#e4261b, 0.3);
}
.remise_block {
  display: flex;

  select {
    width: 20%;
  }
}
</style>
